<template>
    <section>
        <a-page-header @back="$router.go(-1)">
            <template #title>Избранные парковки</template>
        </a-page-header>
        <main>
            <a-row :gutter="54">
                <a-col style="padding-left: 13px; padding-right: 0;">
                    <main v-if="loading">
                        <a-spin />
                    </main>
                    <main v-else>
                        <section class="ant-spin-container" v-if="data?.length > 0">
                            <new-reservation-card v-for="(object) in data" :key="object?.id" :reservation="null"
                                :object="object" allow-reserve is-slot />
                        </section>
                        <a-empty v-else>
                            <template #description>
                                Объектов в избранном нет
                            </template>
                        </a-empty>
                    </main>
                </a-col>

            </a-row>
        </main>

    </section>
</template>
  
<script>
import { onMounted } from "@vue/runtime-core";
import { ref } from "@vue/reactivity";
import NewReservationCard from "@/components/admin/reservation/NewReservationCard";
import { requestAPI as objectAPI } from "@/compositions/objects"

export default {
    name: "ParkingLotsPage",
    setup() {
        const loading = ref(true);
        const data = ref([]);
        const { getFavouriteObjects } = objectAPI();

        onMounted(async () => {
            loading.value = true;
            const res = await getFavouriteObjects('277c0248-f39b-4720-9e3a-7bcc2bf84df7');
            data.value = res?.data;
            loading.value = false;
        });

        return {
            loading,
            data,
        };
    },
    components: {
        NewReservationCard
    }
}
</script>
  
<style lang="less" scoped>
.ant-spin-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
</style>
  